import React from 'react';
import './Resume.css';
import profilePic from './image1.png';

function Resume() {
  return (
    <div className="resume-container">
      <img src={profilePic} alt="Matthew Yaeger" className="profile-pic" />
      <div className="header-text">
        <h1 className="resume-name">Matthew Yaeger</h1>
        <p className="resume-title">Site Reliability Engineer @Thomson Reuters</p>
      </div>
      <div className="resume-contact-info">
        <p>
          <a href="https://www.linkedin.com/in/matthew-yaeger/" target="_blank" rel="noopener noreferrer">linkedin.com/in/matthew-yaeger</a> |
          <a href="mailto:matthewcyaeger@gmail.com"> matthewcyaeger@gmail.com</a> | St. Paul, MN, United States
        </p>
        <p>
          <a href="https://github.com/MatthewYaegerTR" target="_blank" rel="noopener noreferrer"> github.com/MatthewYaegerTR</a> (work) |
          <a href="http://github.com/0xMatthew" target="_blank" rel="noopener noreferrer"> github.com/0xMatthew</a> (personal)
        </p>
      </div>
      <h2>Technical Skills</h2>
      <p>
        Python, C++, Java, JavaScript (React), AWS (6x AWS certified), Azure, Bash, WSL, PowerShell, DNS, distributed tracing and monitoring (Datadog certified), Terraform, Docker, Event-driven architecture, Serverless functions, Kubernetes (EKS, minikube), PyTorch, Machine Learning, LLMs
      </p>
      <h2>About Me</h2>
      <p>
        <em>
          Highly motivated and detail-oriented Site Reliability Engineer at Thomson Reuters with a background in building machine learning applications, cloud-native applications, and DevOps. Recently won the global NVIDIA Gen AI Developer Contest (see GitHub repo: <a href="https://github.com/0xMatthew/CLARA" target="_blank" rel="noopener noreferrer">github.com/0xMatthew/CLARA</a>). I believe in simplicity, collaboration, and getting things done. I seek out compelling software problems that need solutions but currently have few.
        </em>
      </p>
      <h2>Professional Experience</h2>
      <p className="professional-experience-title">Site Reliability Engineer | Thomson Reuters (Sep 2022 - Present)</p>
      <ul>
        <li>Engineered and implemented event-driven/serverless cost optimization solution using AWS EventBridge and Lambda, resulting in substantial monthly cloud infrastructure savings exceeding six figures</li>
        <li>Migrated multiple Thomson Reuters blue-chip applications from on-prem to AWS</li>
        <li>Architected and built fully-automated CI/CD deployment process for application code and IaC of blue-chip CoCounsel AI app</li>
        <li>Designed Terraform and GitHub Actions CI/CD workflow to manage the configuration/deployment of hundreds of headless browser tests across multiple environments with a reusable and modular codebase</li>
        <li>Monitored massive distributed apps and systems using Datadog tracing, logs, and synthetic tests to make data-driven decisions and find bugs before they impact users</li>
      </ul>
      <h2>Education</h2>
      <p>Minot State University | Bachelor of Science in Computer Science | Jan 2021 - May 2022</p>
      <p>University of North Dakota | Computer Science</p>
      <h2>Certifications</h2>
      <table>
        <tr>
          <td>Technoethics: A 21st Century Leadership Competency</td>
          <td>(Achieved July 2024)</td>
        </tr>
        <tr>
          <td>AWS Certified Machine Learning – Specialty</td>
          <td>(Achieved December 2023)</td>
        </tr>
        <tr>
          <td>AWS Certified DevOps Engineer – Professional</td>
          <td>(Achieved November 2023)</td>
        </tr>
        <tr>
          <td>AWS Certified Developer – Associate</td>
          <td>(Achieved September 2023)</td>
        </tr>
        <tr>
          <td>AWS Certified SysOps Administrator – Associate</td>
          <td>(Achieved August 2023)</td>
        </tr>
        <tr>
          <td>AWS Certified Solutions Architect – Associate</td>
          <td>(Achieved July 2023)</td>
        </tr>
      </table>
    </div>
  );
}

export default Resume;
