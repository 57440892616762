import React, { useState } from 'react';
import About from './About';
import Resume from './Resume';
import Projects from './Projects';
import './App.css';

function App() {
  const [page, setPage] = useState('about'); // default page

  return (
    <div className="App">
      <nav>
        <button onClick={() => setPage('about')}>about me</button>
        <button onClick={() => setPage('resume')}>resume</button>
        <button onClick={() => setPage('projects')}>projects</button>
      </nav>
      <div>
        {page === 'about' && <About />}
        {page === 'resume' && <Resume />}
        {page === 'projects' && <Projects />}
      </div>
    </div>
  );
}

export default App;
