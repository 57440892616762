import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h1 className="about-header">About Me</h1>
      <p className="about-content">Hi! My name is Matthew. I'm a motivated engineer who is deeply curious about the world.</p>
      <p className="about-content">I've been obsessed with technology since I was a kid using my dad's Windows 95 PC to play <a href="https://en.wikipedia.org/wiki/Hunter_Hunted_(video_game)" target="_blank" rel="noopener noreferrer">"Hunter Hunted"</a>. I have a diverse job history ranging from construction to software engineering. I've found passion in my career and life through building using mankind's modern tool (computers).</p>
      <p className="about-content">I love the work that I do in my day job at Thomson Reuters. I am very fortunate in that I am afforded the freedom to pursue challenges that align my org's business goals with my own desire to solve the sort of difficult problems that push my abilities to build things I believe are important.</p>
      <p className="about-content">Outside of my day job, I'm always working on some side project (usually software-related). I view creating software as an art form similar to writing music or creating traditional art. I believe the work we do as SWEs is ultimately a creative endeavor and that our creativity is our greatest professional strength.</p>
      <p className="about-content">Outside of computers, I love hiking, talking with people about things that excite them, and traveling with my partner.</p>
      <p className="about-content">Feel free to contact me on LinkedIn to talk tech/the industry at large. I value diversity of opinion, and it's nice to hear from other professionals in our field.</p>
      <p className="about-content">Thanks for reading.</p>
      <div className="about-socials">
        <p>Socials:</p>
        <ul>
          <li><a href="https://www.linkedin.com/in/matthew-yaeger/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
          <li><a href="https://github.com/MatthewYaegerTR" target="_blank" rel="noopener noreferrer">Work GitHub</a></li>
          <li><a href="https://github.com/0xMatthew" target="_blank" rel="noopener noreferrer">Personal GitHub</a></li>
        </ul>
      </div>
    </div>
  );
}

export default About;
