import React from 'react';
import './Projects.css';

function Projects() {
  return (
    <div className="projects-container">
      <h1 className="projects-header">Projects</h1>
      <div className="responsive-iframe-container">
        <iframe 
          className="linkedin-embed" 
          src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7166940513550413825"
          frameBorder="0" 
          allowFullScreen 
          title="Embedded post">
        </iframe>
      </div>
      <div className="responsive-iframe-container">
        <iframe 
          className="linkedin-embed" 
          src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7208193324078256128"
          frameBorder="0" 
          allowFullScreen 
          title="Embedded post">
        </iframe>
      </div>
    </div>
  );
}

export default Projects;
